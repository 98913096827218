@font-face {
  font-family: "Noto Sans";
  src: url("../../fonts/NotoSansGeorgian/NotoSansGeorgian-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Noto Sans";
  src: url("../../fonts/NotoSansGeorgian/NotoSansGeorgian-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Noto Sans";
  src: url("../../fonts/NotoSansGeorgian/NotoSansGeorgian-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Noto Sans";
  src: url("../../fonts/NotoSansGeorgian/NotoSansGeorgian-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Noto Sans";
  src: url("../../fonts/NotoSansGeorgian/NotoSansGeorgian-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Noto Sans";
  src: url("../../fonts/NotoSansGeorgian/NotoSansGeorgian-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Noto Sans";
  src: url("../../fonts/NotoSansGeorgian/NotoSansGeorgian-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Noto Sans";
  src: url("../../fonts/NotoSansGeorgian/NotoSansGeorgian-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Noto Sans";
  src: url("../../fonts/NotoSansGeorgian/NotoSansGeorgian-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}