.preloader {
  @keyframes pulse {
    0% {
      transform: scale(1, 1) translate(0, 0);
    }
    20% {
      transform: scale(1.2, 1.2) translate(-5px, -7px);
    }
  }

  .preloader-inner {
    fill: #fffdfb;
    animation: pulse 1s infinite;
  }
}
