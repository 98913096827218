@font-face {
  font-family: "IQOS";
  src: url("../../fonts/IqosSans/IqosSans-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "IQOS";
  src: url("../../fonts/IqosSans/IqosSans-Bold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}