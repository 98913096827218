@font-face {
  font-family: "Noto Sans";
  src: url("../../fonts/NotoSans/NotoSans-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Noto Sans";
  src: url("../../fonts/NotoSans/NotoSans-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Noto Sans";
  src: url("../../fonts/NotoSans/NotoSans-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Noto Sans";
  src: url("../../fonts/NotoSans/NotoSans-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Noto Sans";
  src: url("../../fonts/NotoSans/NotoSans-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Noto Sans";
  src: url("../../fonts/NotoSans/NotoSans-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Noto Sans";
  src: url("../../fonts/NotoSans/NotoSans-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Noto Sans";
  src: url("../../fonts/NotoSans/NotoSans-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Noto Sans";
  src: url("../../fonts/NotoSans/NotoSans-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Noto Sans";
  src: url("../../fonts/NotoSans/NotoSans-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Noto Sans";
  src: url("../../fonts/NotoSans/NotoSans-ExtraLightItalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Noto Sans";
  src: url("../../fonts/NotoSans/NotoSans-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Noto Sans";
  src: url("../../fonts/NotoSans/NotoSans-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Noto Sans";
  src: url("../../fonts/NotoSans/NotoSans-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Noto Sans";
  src: url("../../fonts/NotoSans/NotoSans-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Noto Sans";
  src: url("../../fonts/NotoSans/NotoSans-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Noto Sans";
  src: url("../../fonts/NotoSans/NotoSans-ExtraBoldItalic.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Noto Sans";
  src: url("../../fonts/NotoSans/NotoSans-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}