@import "./fonts/iqos-sans";
@import "./fonts/noto-sans";
@import "./fonts/noto-sans-georgian";
@import "./fonts/montserrat";

:root {
  --font-main: "IQOS", "Noto Sans", sans-serif;
  --color-darkest: #000000;
  --color-darkest-rgb: 0, 0, 0;
  --color-iq-tuna: #33303c;
  --color-mine-shaft: #313131;
  --color-dove-gray: #6f6f6f;
  --color-iq-silver: #cdcdcd;
  --color-bon-jour: #e1dfe1;
  --color-wild-sand: #f5f5f5;
  --color-iq-pampas: #f6f4f0;
  --color-soft-white: #fffdfb;
  --color-white: #ffffff;
  --color-turquoise: #00d1d2;
  --color-pomegranate: #f32a2a;
  --color-taste-yellow: #eac702;
  --color-taste-amber: #ca5e21;
  --color-taste-silver: #c9c9c9;
  --color-taste-turquoise: #03b5b6;
  --color-taste-purple: #baa5ce;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

$margin-tops: 5, 6, 12, 16, 24, 36, 48;

@each $top in $margin-tops {
  .mt-#{$top} {
    margin-top: #{$top}px;
  }
}

$margin-bottoms: 5, 12, 24;

@each $bottom in $margin-bottoms {
  .mb-#{$bottom} {
    margin-bottom: #{$bottom}px;
  }
}

$max-widths: 200, 320, 420, 640;

@each $max-width in $max-widths {
  .Mw-#{$max-width} {
    max-width: #{$max-width}px;
  }
}

.w-full {
  width: 100%;
}

.reversed-x {
  transform: scaleX(-1);
}